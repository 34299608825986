export const analyticsMutations = {
  FORMATTED_DEVICE_DATA(state, data) {
    const formattedArray = data.map((obj) => {
      return {
        label: obj.itemType,
        value: obj.count,
      };
    });
    state.formattedChartData = formattedArray;
  },
  SET_IS_EDIT_MODE(state, data) {
    state.isEditMode = data;
  },
  SET_SELECTED_DASHBOARD(state, data) {
    state.selectedDashboard = data;
  },
  SET_ADMIN_ROLES(state, data) {
    state.adminRolesDashboardBuilderDropdown = data;
  },
  SET_ADMIN_USERS(state, data) {
    state.adminUsersDashboardBuilderDropdown = data;
  },
  SET_ADMIN_EDITOR_USERS(state, data) {
    state.adminEditorUsersDashboardBuilderDropdown = data;
  },
  SET_DASHBOARD_SELECTED_ROLE(state, data) {
    state.selectedAdminRolesDashboardBuilder = data;
  },
  SET_SELECTED_ADMIN_USERS(state, data) {
    state.selectedAdminUsersDashboardBuilder = data;
  },
  SET_SELECTED_ADMIN_USERS_TO_VIEW(state, data) {
    state.selectedAdminUsersDashboardBuilderToView = data;
  },
  SET_USER_PERMISSIONS_CAN_VIEW_DASHBOARD(state, data) {
    state.permissions.canViewDashboard = data;
  },
  SET_USER_PERMISSIONS_CAN_ASSIGN_DASHBOARD(state, data) {
    state.permissions.canAssignDashboard = data;
  },
  SET_USER_PERMISSIONS_CAN_CREATE_DASHBOARD(state, data) {
    state.permissions.canCreateDashboard = data;
  },
  SET_DASHBOARD_NAME(state, data) {
    state.dashboardBuilderName = data;
  },
  SET_DASHBOARD_PERMISSION(state, data) {
    state.dashboardBuilderPermissions = data;
  },
  SET_DASHBOARDS(state, data) {
    state.dashboards = data;
  },
  SET_DASHBOARD_LAYOUTS(state, data) {
    state.dashboardLayout = data;
  },
  SET_NAME_VALID(state, data) {
    state.dashboardBuilderNameValid = data;
  },
  SET_DASHBOARD_ASSIGN_USER_ACCESS_VALID(state, data) {
    state.dashboardAssignUserAccessValid = data;
  },
  SET_DASHBOARD_ASSIGN_ROLE_ACCESS_VALID(state, data) {
    state.dashboardAssignRoleAccessValid = data;
  },
  SET_DASHBOARD_ASSIGN_USER_VIEW_ACCESS_VALID(state, data) {
    state.dashboardAssignUserViewAccessValid = data;
  },
  SET_DASHBOARD_BUILDER_FORM_VALID(state, data) {
    state.dashboardFormValid = data;
  },
  SET_SINGLE_DATE_FILTER(state, data) {
    state.singleDateFilter = data;
  },
  SET_SINGLE_DATE_FILTER_VALUE_START(state, data) {
    state.singleDateFilterValueStart = data;
  },
  SET_SINGLE_DATE_FILTER_VALUE_END(state, data) {
    state.singleDateFilterValueEnd = data;
  },
};
