const CHART_TYPES = {
  PieChart: 'PieChart',
  BarChart: 'BarChart',
  ColumnChart: 'ColumnChart',
  MsBarChart: 'MsBarChart',
  MsColumnChart: 'MsColumnChart',
  NumericChart: 'NumericChart',
};
const CHART_IMAGES = {
  PieChart: 'Pie 2D.png',
  BarChart: 'Bar Chart.png',
  ColumnChart: 'Column 2D.png',
  MsBarChart: 'MultiSeriese Bar 2D.png',
  MsColumnChart: 'Stacked Bar Chart.png',
  NumericChart: 'Numeric.png',
};

export const chartLibraryItemsInternalList = [
  {
    chartCategoryId: 0,
    chartCategoryName: 'Engagement Statistics',
    charts: [
      {
        chartId: 0,
        chartName: 'Devices Connected by Device Type',
        dateRangeType: 'Single',
        chartType: CHART_TYPES.PieChart,
        description: 'description',
        sampleImage: CHART_IMAGES.PieChart,
        endpoint: 'devices-connected-by-type',
        chartSettings: {
          paletteColors: 'mkstandard',
          showLabels: '0',
        },
        sampleData: [
          {
            label: 'Apple Health',
            value: 20,
          },
          {
            label: 'Garmin',
            value: 40,
          },
          {
            label: 'Apple Watch',
            value: 10,
          },
          {
            label: 'Fitbit',
            value: 30,
          },
        ],
      },
      {
        chartId: 1,
        chartName: 'Total Logins',
        dateRangeType: 'Single date range',
        chartType: CHART_TYPES.NumericChart,
        description: 'Displays the total number of logins in the report date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'total-logins',
        chartSettings: {
          paletteColors: 'mkstandard',
        },
        sampleData: 789100,
      },
      {
        chartId: 2,
        chartName: 'Top 5 Challenges by Member Enrollment (%)',
        dateRangeType: 'Single',
        chartType: CHART_TYPES.BarChart,
        description:
          'Displays the top 5 challenges by the percentage of members who enrolled in the challenge in the report range.',
        sampleImage: CHART_IMAGES.BarChart,
        endpoint: 'top-5-challenges-by-enrollment',
        chartSettings: {
          paletteColors: 'mkstandard',
          numbersuffix: '%',
        },
        sampleData: [
          {
            label: 'Stress Less in the New Year Challenge 2024',
            value: 72,
          },
          {
            label: 'Unplug Those Devices Challenge 2024',
            value: 57,
          },
          {
            label: 'Go the Distance Miles Challenge 2024 - miles',
            value: 38,
          },
          {
            label: 'Stay Hydrated Team Challenge 2024',
            value: 44,
          },
          {
            label: 'Go Green Save the Planet Challenge',
            value: 25,
          },
        ],
      },
      {
        chartId: 10,
        chartName: 'Engagement by Key Activities',
        dateRangeType: 'Comparison',
        chartType: CHART_TYPES.MsBarChart,
        description:
          'Compare the percentage of eligible members who have registered, completed the HRA, completed at least 1 challenge, earned points, completed a program, connected a device, or joined a social circle over. Each series represents a different date range, allowing for easy comparison between time periods.',
        sampleImage: CHART_IMAGES.MsBarChart,
        endpoint: 'engagement-by-Key-activities',
        chartSettings: {
          paletteColors: 'mkstandard',
        },
        categories: [
          {
            category: [
              {
                label: 'Registered',
              },
              {
                label: 'Completed the HRA',
              },
              {
                label: 'Completed at least 1 challenge',
              },
              {
                label: 'Completed a program',
              },
              {
                label: 'Connected a device',
              },
              {
                label: 'Joined a social circle',
              },
            ],
          },
        ],
        sampleData: [
          {
            seriesname: '01/01/2020 - 01/01/2022',
            data: [
              {
                value: '200',
              },
              {
                value: '100',
              },
              {
                value: '110',
              },
              {
                value: '70',
              },
              {
                value: '150',
              },
              {
                value: '20',
              },
            ],
          },
          {
            seriesname: '01/01/2022 - 01/01/2024',
            data: [
              {
                value: '350',
              },
              {
                value: '220',
              },
              {
                value: '210',
              },
              {
                value: '90',
              },
              {
                value: '110',
              },
              {
                value: '10',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    chartCategoryId: 1,
    chartCategoryName: 'Health Assessment Statistics',
    charts: [
      {
        chartId: 100,
        chartName: 'HRA Completed by Age Group (%)',
        dateRangeType: 'Single',
        chartType: CHART_TYPES.ColumnChart,
        description: 'Displays the percent of members who took the HRA by age group in the report range.',
        sampleImage: CHART_IMAGES.ColumnChart,
        endpoint: 'hra-by-age-group',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 0,
          numbersuffix: '%',
        },
        sampleData: [
          {
            label: '16-17',
            value: 100,
          },
          {
            label: '18-29',
            value: 20,
          },
          {
            label: '30-39',
            value: 40,
          },
          {
            label: '40-49',
            value: 80,
          },
          {
            label: '50-59',
            value: 30,
          },
          {
            label: '60+',
            value: 60,
          },
        ],
      },
      {
        chartId: 101,
        chartName: 'HRA Completed by Gender (%)',
        dateRangeType: 'Single date range',
        chartType: CHART_TYPES.PieChart,
        description: 'Displays the percent of members who took the HRA by gender in the report range.',
        sampleImage: CHART_IMAGES.PieChart,
        endpoint: 'hra-by-gender',
        chartSettings: {
          paletteColors: 'mkstandard',
          showLabels: '0',
          numbersuffix: '%',
        },
        sampleData: [
          {
            label: 'Male',
            value: 47,
          },
          {
            label: 'Female',
            value: 53,
          },
        ],
      },
      {
        chartId: 102,
        chartName: 'Meet Exercise Recommendations by BMI Class (%)',
        dateRangeType: 'Single date range',
        chartType: CHART_TYPES.BarChart,
        description:
          'Displays the percentage of members who meet exercise recommendations by BMI class in the report range.',
        sampleImage: CHART_IMAGES.BarChart,
        endpoint: 'members-bmi-class',
        chartSettings: {
          paletteColors: 'mkstandard',
          numbersuffix: '%',
        },
        sampleData: [
          {
            label: 'Underweight (<18.5)',
            value: 3,
          },
          {
            label: 'Normal (18.5-24.9)',
            value: 12,
          },
          {
            label: 'Overweight (25-29.9)',
            value: 46,
          },
          {
            label: 'Obese (Class 1/Class II - 30-39.9)',
            value: 35,
          },
          {
            label: 'Extremely Obese (Class III >=40)',
            value: 4,
          },
        ],
      },
      {
        chartId: 103,
        chartName: ' Self-Reported Health Status Breakdown (%)',
        dateRangeType: 'Single date range',
        chartType: CHART_TYPES.PieChart,
        description: 'Displays the percentage of members by self-reported health status in the report range.',
        sampleImage: CHART_IMAGES.PieChart,
        endpoint: 'self-reported-health-status',
        chartSettings: {
          paletteColors: 'mkstandard',
          numbersuffix: '%',
          showLabels: '0',
        },
        sampleData: [
          {
            label: 'Poor',
            value: 13,
          },
          {
            label: 'Fair',
            value: 25,
          },
          {
            label: 'Good',
            value: 38,
          },
          {
            label: 'Excellent',
            value: 16,
          },
          {
            label: 'Unreported',
            value: 8,
          },
        ],
      },
    ],
  },
];
